export const FirebaseConfig = {
	"projectId": "agncabs-64714",
	"appId": "1:48259787822:web:d962934d1752bb5c7143bf",
	"databaseURL": "https://agncabs-64714-default-rtdb.firebaseio.com",
	"storageBucket": "agncabs-64714.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCEAtrSbAlsYh3DPywM3lc84NxljaenFgw",
	"authDomain": "agncabs-64714.firebaseapp.com",
	"messagingSenderId": "48259787822",
	"measurementId": "G-V1N2SJMYSN"
};