export const FareCalculator = (
    distance,
    time,
    rateDetails,
    instructionData,
    decimal,
    trip
  ) => {
    // let baseCalculated =  (parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance)) + (parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600));
    let baseCalculated = 0;
    if (trip === "dropping") {
      if (parseFloat(distance) >= 0 && parseFloat(distance) <= 10) {
        if (rateDetails.name === "MINI") {
          baseCalculated =
            parseFloat(distance) * 15 +
            (parseFloat(time) / 60) * 2.25 +
            rateDetails.base_fare;
        } else if (rateDetails.name === "SEDAN") {
          baseCalculated =
            parseFloat(distance) * 17 +
            (parseFloat(time) / 60) * 2.75 +
            rateDetails.base_fare;
        } else if (rateDetails.name === "SUV") {
          baseCalculated =
            parseFloat(distance) * 19 +
            (parseFloat(time) / 60) * 3.0 +
            rateDetails.base_fare;
        }
      } else if (parseFloat(distance) > 10 && parseFloat(distance) < 125) {
        if (rateDetails.name === "MINI") {
          baseCalculated =
            parseFloat(distance) * 15 +
            (parseFloat(time) / 60) * 2.25 +
            rateDetails.base_fare;
        } else if (rateDetails.name === "SEDAN") {
          baseCalculated =
            parseFloat(distance) * 17 +
            (parseFloat(time) / 60) * 2.75 +
            rateDetails.base_fare;
        } else if (rateDetails.name === "SUV") {
          baseCalculated =
            parseFloat(distance) * 19 +
            (parseFloat(time) / 60) * 3.0 +
            rateDetails.base_fare;
        }
      } else if (parseFloat(distance) >= 125) {
        if (rateDetails.name === "MINI") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 21 + rateDetails.base_fare;
          } else {
            baseCalculated =
              parseFloat(distance) * 21 +
              (parseFloat(time) / 60 - 720) * 2.25 +
              rateDetails.base_fare;
          }
        } else if (rateDetails.name === "SEDAN") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 23 + rateDetails.base_fare;
          } else {
            baseCalculated =
              parseFloat(distance) * 23 +
              (parseFloat(time) / 60 - 720) * 2.75 +
              rateDetails.base_fare;
          }
        } else if (rateDetails.name === "SUV") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 25 + rateDetails.base_fare;
          } else {
            baseCalculated =
              parseFloat(distance) * 25 +
              (parseFloat(time) / 60 - 720) * 3.0 +
              rateDetails.base_fare;
          }
        }
      }
    } else if (trip === "round") {
      if (parseFloat(distance)>= 0 && parseFloat(distance)<= 20) {
        if (rateDetails.name === "MINI") {
          baseCalculated =
            parseFloat(distance)* 15 +
            ((parseFloat(time)) / 60) * 2.25 +
            rateDetails.base_fare;
        } else if (rateDetails.name === "SEDAN") {
          baseCalculated =
            parseFloat(distance)* 17 +
            ((parseFloat(time)) / 60) * 2.75 +
            rateDetails.base_fare;
        } else if (rateDetails.name === "SUV") {
          baseCalculated =
            parseFloat(distance)* 19 +
            ((parseFloat(time)) / 60) * 3.0 +
            rateDetails.base_fare;
        }
      } else if (
        parseFloat(distance)> 20 &&
        parseFloat(distance)< 250
      ) {
        if (rateDetails.name === "MINI") {
          baseCalculated =
            parseFloat(distance)* 11.5 +
            ((parseFloat(time)) / 60) * 1.25 +
            rateDetails.base_fare;
        } else if (rateDetails.name === "SEDAN") {
          baseCalculated =
            parseFloat(distance)* 12.5 +
            ((parseFloat(time)) / 60) * 1.5 +
            rateDetails.base_fare;
        } else if (rateDetails.name === "SUV") {
          baseCalculated =
            parseFloat(distance)* 13.5 +
            ((parseFloat(time)) / 60) * 1.75 +
            rateDetails.base_fare;
        }
      } else if (parseFloat(distance)>= 250) {
        if (rateDetails.name === "MINI") {
          if ((parseFloat(time)) / 60 <= 720) {
            baseCalculated =
              parseFloat(distance)* 12 + rateDetails.base_fare;
          } else {
            baseCalculated =
              parseFloat(distance)* 12 +
              ((parseFloat(time)) / 60 - 720) * 1.25 +
              rateDetails.base_fare;
          }
        } else if (rateDetails.name === "SEDAN") {
          if ((parseFloat(time)) / 60 <= 720) {
            baseCalculated =
              parseFloat(distance)* 13 + rateDetails.base_fare;
          } else {
            baseCalculated =
              parseFloat(distance)* 13 +
              ((parseFloat(time)) / 60 - 720) * 1.5 +
              rateDetails.base_fare;
          }
        } else if (rateDetails.name === "SUV") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated =
              parseFloat(distance)* 14 + rateDetails.base_fare;
          } else {
            baseCalculated =
              parseFloat(distance)* 14 +
              ((parseFloat(time)) / 60 - 720) * 1.75 +
              rateDetails.base_fare;
          }
        }
      }
    } else if (trip === "rental") {
      if (parseFloat(distance) >= 0 && parseFloat(distance) <= 20) {
        if (rateDetails.name === "MINI") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 9 + 1000;
          } else {
            baseCalculated =
              parseFloat(distance) * 9 +
              (parseFloat(time) / 60 - 720) * 1.25 +
              1000;
          }
        } else if (rateDetails.name === "SEDAN") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 10 + 1100;
          } else {
            baseCalculated =
              parseFloat(distance) * 10 +
              (parseFloat(time) / 60 - 720) * 1.5 +
              1100;
          }
        } else if (rateDetails.name === "SUV") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 11 + 1200;
          } else {
            baseCalculated =
              parseFloat(distance) * 11 +
              (parseFloat(time) / 60 - 720) * 1.75 +
              1200;
          }
        }
      } else if (parseFloat(distance) > 20 && parseFloat(distance) < 250) {
        if (rateDetails.name === "MINI") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 9 + 1000;
          } else {
            baseCalculated =
              parseFloat(distance) * 9 +
              (parseFloat(time) / 60 - 720) * 1.25 +
              1000;
          }
        } else if (rateDetails.name === "SEDAN") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 10 + 1100;
          } else {
            baseCalculated =
              parseFloat(distance) * 10 +
              (parseFloat(time) / 60 - 720) * 1.5 +
              1100;
          }
        } else if (rateDetails.name === "SUV") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 11 + 1200;
          } else {
            baseCalculated =
              parseFloat(distance) * 11 +
              (parseFloat(time) / 60 - 720) * 1.75 +
              1200;
          }
        }
      } else if (parseFloat(distance) >= 250) {
        if (rateDetails.name === "MINI") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 9 + 1000;
          } else {
            baseCalculated =
              parseFloat(distance) * 9 +
              (parseFloat(time) / 60 - 720) * 1.75 +
              1000;
          }
        } else if (rateDetails.name === "SEDAN") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 10 + 1100;
          } else {
            baseCalculated =
              parseFloat(distance) * 10 +
              (parseFloat(time) / 60 - 720) * 1.75 +
              1100;
          }
        } else if (rateDetails.name === "SUV") {
          if (parseFloat(time) / 60 <= 720) {
            baseCalculated = parseFloat(distance) * 11 + 1200;
          } else {
            baseCalculated =
              parseFloat(distance) * 11 +
              (parseFloat(time) / 60 - 720) * 1.75 +
              1200;
          }
        }
      }
    } else {
      // baseCalculated =  (parseFloat(rateDetails.rate_per_unit_distance) * parseFloat(distance)) + (parseFloat(rateDetails.rate_per_hour) * (parseFloat(time) / 3600)) + rateDetails.base_fare;
    }
    // if(rateDetails.base_fare>0){
    //     baseCalculated = baseCalculated + rateDetails.base_fare;
    // }
    if (instructionData && instructionData.parcelTypeSelected) {
      baseCalculated = baseCalculated + instructionData.parcelTypeSelected.amount;
    }
    if (instructionData && instructionData.optionSelected) {
      baseCalculated = baseCalculated + instructionData.optionSelected.amount;
    }
    let total =
      baseCalculated > parseFloat(rateDetails.min_fare)
        ? baseCalculated
        : parseFloat(rateDetails.min_fare);
    let convenienceFee = 0;
    if (
      rateDetails.convenience_fee_type &&
      rateDetails.convenience_fee_type == "flat"
    ) {
      convenienceFee = rateDetails.convenience_fees;
    } else {
      convenienceFee = (total * parseFloat(rateDetails.convenience_fees)) / 100;
    }
    let grand = total + convenienceFee;
  
    return {
      totalCost: parseFloat(total.toFixed(decimal)),
      grandTotal: parseFloat(grand.toFixed(decimal)),
      convenience_fees: parseFloat(convenienceFee.toFixed(decimal)),
    };
  };
  